import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BusinessDetailService } from '../../business-details/service/business-detail.service';
import { Data } from '../../providers/data';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class PersonalAddressResolver implements Resolve<any> {
  constructor(private businessDetailService: BusinessDetailService, private data: Data) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.businessDetailService.fetchCountries()];
        return forkJoin(requests).pipe(map((res) => {
          let response = {
            countries : res[0]
          }
          return response;
        }));

   
  }
}
