<div class="sidebar">
  <div class="logo sidebar_row">
    <img
      class="logo-img"
      src="../../../../../assets/Q_banc_logo.png"
      alt="logo"
    />
  </div>

  <div class="heading-text">
    <h1>WELCOME!</h1>
    <p>Unlock the potential of your money with the power of smarter wallets.</p>
  </div>
  <div class="col s6 sidebar_row">
    <p class="footer-text-one">
      (C)2023 Universal Securities & Investments Limited.
    </p>
  </div>
</div>

<!-- Sidebar for mobile devices-->
<div class="sidebar_mobile">
  <div>
    <div class="logo sidebar_row col">
      <img
        class="logo-img"
        src="../../../../bower_components/materialize/images/logo/Logo.png"
        alt="logo"
      />
      <div class="heading-text col">
        <h1>Business Portal</h1>
      </div>
    </div>
  </div>
</div>
