import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenricateService } from '../service/authenricate.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AccountService } from '../../account-details/service/account.service';
import { Account } from 'src/app/account-details/model/account';

declare var $: any;

@Component({
  selector: 'email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.scss']
})


export class EmailCheckComponent {
  public isSuccess: boolean = false;
  public isError: boolean=false;
  public submitted = false;
  public number: any
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: any;
  token: any;
  
  constructor(private authenticateService: AuthenricateService, private ngxService: NgxUiLoaderService,
  private router: Router, private formBuilder : FormBuilder, private accountService: AccountService,
  private route: ActivatedRoute, private authorizationService: AuthenricateService,) { 

    this.route.queryParams.subscribe(params => {
      this.firstName = params['firstName'];
      this.lastName = params['lastName'];
      this.phoneNumber = params['phoneNumber'];
      this.email = params['email'];
      this.token = params['token'];
    });
  }

  emailForm = this.formBuilder.group({
    userEmail : ['',  [Validators.required, Validators.email]]
  });

  get f(){return this.emailForm.controls;}

  ngOnInit() {

    const token = atob(this.token);
    this.authorizationService.setToken(token);
    localStorage.setItem('userPhone', this.phoneNumber);
    localStorage.setItem('firstName', this.firstName);
    localStorage.setItem('lastName', this.lastName);
    this.emailForm.controls.userEmail.setValue(this.email);
    this.emailForm.controls.userEmail.disable()
  }

  verifyEmail(){
    this.submitted = true;
    if (this.emailForm.invalid){
      return;
    }
    else
    {
      this.ngxService.start();
      this.authenticateService.verifyEmail(this.emailForm.getRawValue().userEmail).subscribe((result) => {
        if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
            if(result.responseData.customer){
              // this.isError=true;
              let status=result.responseData.customer.status;

              if(status===0 || status===1 || status===2 || status===3 || status===4){
               let apiResponse=result.responseData.customer;
               localStorage.setItem('userId', apiResponse.id+"");
            let accountModel = new Account(apiResponse.id,apiResponse.fullName,apiResponse.mobileNo,
              apiResponse.email,apiResponse.type,apiResponse.status);

          if(apiResponse.status===4){
            this.ngxService.stop();
            this.isError=true;
          }else{
            this.accountService.saveUser(accountModel).subscribe((result) => {
              if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
                this.router.navigate(['/verifyOTP']);
                this.ngxService.stop();
              }
              else{
                this.isError=true;
                this.ngxService.stop();
              }

            })
          }

              }
              else{
                this.ngxService.stop();
              }
              
            }
        }
        else if(result != null && result.code === 'DAO_ERROR_04'){
          this.ngxService.stop();
          this.isError=false;
          this.isSuccess = true;
          localStorage.setItem('userEmail', this.emailForm.value.userEmail+"");
          this.router.navigate(['/accountDetails']);
        }
        else {
          this.ngxService.stop();
          this.isError = true;
        }

      }, error => { console.log(error) });
    }
  }

}