import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { Data } from 'src/app/providers/data';
import {BusinessCustomer} from '../model/businessCustomer';
import { Business } from 'src/app/business-details/model/business';


declare var $: any;

@Component({
  selector: 'personal-doctype',
  templateUrl: './personal-doctype.component.html',
  styleUrls: ['./personal-doctype.component.scss']
})


export class PersonalDocumentTypeComponent  implements OnInit{
  public business : Business;
  public customer: BusinessCustomer;

  constructor(private router: Router){}


  ngOnInit() {
  // this.business=this.data.storage.businessDetails;
  }

  addPassport(){
    // this.data.storage = {
    //   businessInformation: this.business
    // };
    this.router.navigate(['/passportDocuments']);
  }

  addNic(){
    // this.data.storage = {
    //   businessInformation: this.business
    // };
    this.router.navigate(['/personalDocuments']);
  }


}