import { Injectable } from '@angular/core';
import { HttpService } from '../../http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../../sign-up/system/system.constants';
import { Observable } from 'rxjs';
import { Headers } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { AuthenricateService } from 'src/app/email-check/service/authenricate.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PersonalDetailService {

  private baseUrl = environment.api_base_url;
  private digibaseUrl = environment.digibase_url
  private headers = SystemConstants.header;

  constructor(private httpService:HttpService,
    private ngxLoader: NgxUiLoaderService, private authorizationService: AuthenricateService) { }


    // sendPersonalDocument(file: File): Observable<any> {
    //   let formData:FormData = new FormData();
    //   formData.append('file', file);
    //   return this.httpService.post(this.baseUrl+"file/addFile",formData)
    //   .pipe(map((res:any) => {
    //     this.ngxLoader.stop();
    //     return JSON.parse(res._body);
    //   })).pipe(catchError((error:any) => {
    //     console.log(error);
    //     this.ngxLoader.stop();
    //     return error;
    //   }));
    // }

    sendPersonalDocument(file: File, type: any, indentity: any): Observable<any> {
      let formData:FormData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      formData.append('identity', indentity);
      return this.httpService.post(this.baseUrl+"documentUpload/upload",formData)
      .pipe(map((res:any) => {
        this.ngxLoader.stop();
        return JSON.parse(res._body);
      })).pipe(catchError((error:any) => {
        console.log(error);
        this.ngxLoader.stop();
        return error;
      }));
    }


    createBusinessCustomer(object: any): Observable<any> {
      let headers = new Headers();
      headers.append("Content-Type","application/json");
      let request = JSON.stringify({ "customer": object});    
      return this.httpService.post(this.baseUrl+"customer/register",request,{headers})
      .pipe(map((res:any) => {
        this.ngxLoader.stop();   
        return JSON.parse(res._body);
      })).pipe(catchError((error:any) => {
        console.log(error);
        this.ngxLoader.stop();
        return error;
      })); 
    }

    customerUploadDocument(object: any): Observable<any> {
      let headers = new Headers();
      headers.append("Content-Type","application/json");
      let request = JSON.stringify({ "customer": object});    
      return this.httpService.post(this.baseUrl+"customer/document",request,{headers})
      .pipe(map((res:any) => {
        this.ngxLoader.stop();   
        return JSON.parse(res._body);
      })).pipe(catchError((error:any) => {
        console.log(error);
        this.ngxLoader.stop();
        return error;
      })); 
    }


    getSelectedCustomer(id): Observable<any> {
      return this.httpService.get(this.baseUrl+`customer/search/account?id=${id}`)
            .pipe(map((res: any) => {
              // return JSON.parse(res._body);
              return res.json();
            })).pipe(catchError((error:any) => {
              console.log(error);
              return error;
      }));
    }

    fetchCountries(): Observable<any> {
      return this.httpService.get(this.baseUrl + "country/list")
        .pipe(map((res: any) => {
          return res.json();
        })).pipe(catchError((error: any) => {
          console.log(error);
          return error;
        }));
    }

  saveIdScanData(body: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
  
    return this.httpService.post(this.baseUrl+"idScanAuth/save",body,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  
  sendCustomerNotification(id): Observable<any> {
      let token = this.authorizationService.getToken();
      let headers = new Headers();
      headers.append("Content-Type","application/json");
      headers.append("Authorization", `QBR ${token}`);
    return this.httpService.get(this.digibaseUrl+`business/sendCustomerNotify?id=${id}`, {headers})
      .pipe(map((res: any) => {
        // return JSON.parse(res._body);
        return res.json();
      })).pipe(catchError((error:any) => {
        console.log(error);
        return error;
    }));
  }

  searchAddress(body): Observable<any> {
    return this.httpService.post(this.baseUrl+`loqate/findAddressCaptureV1`, body)
      .pipe(map((res: any) => {
        // return JSON.parse(res._body);
        return res.json();
      })).pipe(catchError((error:any) => {
        console.log(error);
        return error;
    }));
  }

}
