import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Business } from 'src/app/business-details/model/business';
import { Address } from 'src/app/business-details/model/address';
import { selectBusiness } from '../../../app/business-details/store/selector/business.selectors'
import { addPersonalAddr } from '../store/action/personalAddress.actions';
import { select, Store } from '@ngrx/store';
import { addPerson } from '../store/action/personal.actions';
import { selectPersonalAddress } from '../store/selector/personalAddress.selectors'
import { FormBuilder, Validators } from '@angular/forms';
import { selectPersonal } from '../../personal-details/store/selector/personal.selectors';
import { BusinessCustomer } from '../model/businessCustomer';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { selectCustomers } from '../../account-details/store/selector/customer.selectors';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PersonalDetailService } from '../service/personal-detail.service';
import { Account } from 'src/app/account-details/model/account';
import { environment } from 'src/environments/environment';


declare var $: any;

@Component({
  selector: 'personal-address',
  templateUrl: './personal-address.component.html',
  styleUrls: ['./personal-address.component.scss']
})


export class PersonalAddressComponent implements OnInit {
  public business: Business;
  public finalCustomer: BusinessCustomer;
  public submitted = false;
  public customer: BusinessCustomer;
  public prevCustomer: Account;
  public accType: string;
  public states: any;
  public countries: any;
  public cities: any;
  public previousBusinessResponse: any;
  public previousResponse: any;
  public prevPersonal: BusinessCustomer;
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public closeResult: string;
  public redirectUrl: any = environment.idScanUrl;
  public businessID: any = localStorage.getItem('businessID');

  searchText: string = '';
  addressArray: any[] = [];

  personalDetailForm = this.formBuilder.group({
    line1: ['', [Validators.required, Validators.minLength(6)]],
    line2: [''],
    state: ['', Validators.required],
    postalCode: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required]
  })
  get f() { return this.personalDetailForm.controls };


  constructor(private router: Router, private route: ActivatedRoute, private store: Store<BusinessCustomer>,
    private modalService: NgbModal, private ngxService: NgxUiLoaderService, private personalDetailService: PersonalDetailService,
    private formBuilder: FormBuilder) {

    this.route.data.subscribe(response => {
      let res = response[0].countries;
      if (res.code === 'DAO_SUCCESS_00') {
        this.countries = res.responseData.countries;
      }
    })

    this.store.pipe(select(selectBusiness)).subscribe((result) => {
      this.previousBusinessResponse = result;      
    })

    this.store.pipe(select(selectCustomers)).subscribe((result: any) => {
      this.prevCustomer = result;
    });

    this.store.pipe(select(selectPersonal)).subscribe((result: any) => {
      this.prevPersonal = result;
    });

    this.store.pipe(select(selectPersonalAddress)).subscribe((result: any) => {
      this.previousResponse = result;
    })

    //  console.log(this.prevPersonal);
  }


  ngOnInit() {
    if (this.previousResponse) {
      this.personalDetailForm.patchValue(this.previousResponse)
    }

    this.personalDetailForm.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
    });

    this.personalDetailForm.get('state').valueChanges.subscribe(val => {
      this.fetchCities(val);
    });

  }

  addPersonalAddress(modal) {
    this.submitted = true;
    let checkPrevType = this.prevPersonal.type;
    if (checkPrevType === "operator") {
      this.accType = "operator";
    }
    else {
      this.accType = "stackholder";
    }

    if (this.personalDetailForm.invalid) {
      return;
    }
    else {

      const addressModel = new Address(null, this.personalDetailForm.value.line1,
        this.personalDetailForm.value.line2, this.personalDetailForm.value.city, this.personalDetailForm.value.postalCode,
        this.personalDetailForm.value.state, this.personalDetailForm.value.country);

      this.store.dispatch(addPersonalAddr(addressModel));

      let accountModel = new Account(this.prevCustomer.id, this.prevCustomer.fullName, this.prevCustomer.mobileNo,
        this.prevCustomer.email, this.prevCustomer.type, this.prevCustomer.status);

      let custModel = new BusinessCustomer(this.prevPersonal.id, this.previousBusinessResponse.id, accountModel, this.accType, this.prevPersonal.firstName,
        this.prevPersonal.lastName, this.prevPersonal.gender, this.prevPersonal.nationality,
        this.prevPersonal.dateOfBirth, 0, addressModel, null);

      this.finalCustomer = custModel;
      this.createCustomer(modal);

      // this.router.navigate(['/personalDoctype']);
      // this.router.navigate(['/kycType']);
    }
  }

  openmodal(content) {
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate(['/personalDoctype']);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createCustomer(modal) {
    this.ngxService.start();
    this.personalDetailService.createBusinessCustomer(this.finalCustomer).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {

        //  let res=
        // var custModel = new BusinessCustomer(null,this.previousBusinessResponse.id, accountModel,"operator",this.personalDetailForm.value.firstName, 
        // this.personalDetailForm.value.lastName,this.personalDetailForm.value.gender,this.personalDetailForm.value.nationality, 
        // this.personalDetailForm.value.dateOfBirth, 0,null,null);
        this.store.dispatch(addPerson(result.responseData.customer));

        this.isSuccess = true;
        this.ngxService.stop();
        // this.routeIdScanUrl();
        this.customerNotification();
        // this.router.navigate(['/personalDoctype']);
        // this.openmodal(modal);
      }
      else {
        this.ngxService.stop();
        this.isError = true;
      }
    }, error => { console.log(error) });

  }

  onPrevious() {
    this.router.navigate(['/personalDetails']);
  }

  fetchCities(stateId) {
    this.states.map(x => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  routeIdScanUrl() {
      const idScanUrl = this.redirectUrl
      window.location.href = `${idScanUrl}?type=business`;
    }

  customerNotification(){
    this.personalDetailService.sendCustomerNotification(this.businessID).subscribe((resp: any)=>{
      console.log(resp);
      setTimeout(() => {
        this.router.navigate(['/createAccount']);
      }, 500);
    });
  }

  search() {
    let body = {
      address: this.searchText,
      limit:10,
      countries:"GB"
    }
    this.personalDetailService.searchAddress(body).subscribe((response: any) => {
      if(response.code === "DAO_SUCCESS_00") {
        this.addressArray = response.responseData.Data?.items;
      }
    });
  }

}