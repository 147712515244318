import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './http.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './reducers';
import { BrowserModule } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import {BusinessTypeComponent} from './business-type/component/business-type.component';
import { AppRoutingModule } from './app-routing.module';
import { RequestOptions, XHRBackend } from '@angular/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from '../app/sign-up/component/sign-up.component';
import { Data } from '../app/providers/data';
import {SignUpModule} from '../app/sign-up/sign-up.module';
import {PassbaseModule} from '../app/pass-base-verification/passbase.module';
import {BusinessTypeModule} from './business-type/business-type.module';
import { HttpModule } from '@angular/http';
import {EmailCheckModule} from './email-check/email-check.module';
import { EmailCheckComponent } from './email-check/component/email-check.component';
import {AccountDetailsModule} from './account-details/account-details.module';
import {VerifyOTPModule} from './verify-otp/verify-otp.component.module';
import {BusinessDetailsModule} from './business-details/business-details.module';
import {PersonalDetailsModule} from './personal-details/personal-details.module';
import {BioVerificationModule} from './bio-verification/bio-verification.module';
import {CreateAccountModule} from './create-account/create-account.module';
import { KycTypeComponent } from './kyc-type/kyc-type.component';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RewiewDocumentsComponent } from './rewiew-documents/rewiew-documents.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UIModule} from './util/ui/ui-module';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {

  "bgsColor": "blue",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "blue",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "blue",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

@NgModule({
  declarations: [
    AppComponent,
    KycTypeComponent,
    RewiewDocumentsComponent,
 
  
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AppRoutingModule,
    SignUpModule,
    EmailCheckModule,
    AccountDetailsModule,
    VerifyOTPModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    BusinessDetailsModule,
    PersonalDetailsModule,
    BioVerificationModule,
    CreateAccountModule,
    PassbaseModule,
    ReactiveFormsModule,
    FormsModule,
    HttpModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UIModule
  ],
  providers: [Data,{
    provide: HttpService,
    useFactory: (backend: XHRBackend, options: RequestOptions) => {
      return new HttpService(backend, options);
    },
    deps: [XHRBackend, RequestOptions]
  },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
