<card-container>
<div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
        <div class="jYRwVK">
                    <div class="main-panel__content">
                        <div class="husZCp">
                            <h1 data-component="heading"
                                class="main-panel__heading nonreferral">Business Document Information</h1>
                            <h2 data-component="subheading"
                                class="main-panel__subheading">Please upload all the required documents </h2>
                            </div>
                    </div>
                   
             
<!-- <br> -->
            <form name="f" [formGroup]="documentsForm" >
                <div class="form__group">
                    <div class="form__group">
                        <label data-component="form-input-email-label" for="email"
                        class="form__label">Proof of Business Registration</label>
                        <input type="file" placeholder="" #imageInput
                        (change)="processFile(imageInput)"
                        formControlName="businessRegistrationDocument"
                        [ngClass]="{'invalid': submitted &&  f.businessRegistrationDocument.errors }"
                        class="form__input validate-empty" value="businessRegistrationDocument" >
                        <div *ngIf="submitted && f.businessRegistrationDocument.errors"
                        class="text-danger">
                        <div *ngIf="f.businessRegistrationDocument.errors.required">Proof of Business Registration is required</div>
                    </div>
                    <div class="p-text">Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are allowed</div>
                    </div>
                </div>
                <div data-component="form-input-name" class="form__group">
                    <div class="form__group">
                        <label data-component="form-input-name-label" for="name"
                        class="form__label">Proof of National Tax Number</label>
                        <input #imageInputs type="file" (change)="processFile(imageInputs)"
                        formControlName="ntnDocument"
                        value="ntnDocument"
                        [ngClass]="{'invalid': submitted &&  f.ntnDocument.errors }"
                        class="form__input validate-empty" >
                        <div *ngIf="submitted && f.ntnDocument.errors" class="text-danger">
                            <div *ngIf="f.ntnDocument.errors.required">Proof of National Tax Number is required</div>
                        </div>
                        <div class="p-text">Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are allowed</div>
                        </div>
                </div>
                <div class="row">
                    <div *ngIf="bDocument1" class="input-field col s4">
                        <label for="bDocument1" class="active">Document 1</label>
                        <img id="myimage" src="{{imageUrl}}file/view/{{bDocument1}}" />
                        <!-- <input type="text" formControlName="bDocument1" class="form-control" id="bDocument1" /> -->
                    </div>
                      <div *ngIf="bDocument2" class="input-field col s4">
                        <label for="bDocument2" class="active">Document 2</label>
                        <img id="myimage"src="{{imageUrl}}file/view/{{bDocument2}}" />
                        <!-- <input type="text" formControlName="bDocument2" class="form-control" id="bDocument2" /> -->
                      </div>
                </div>

                <div class="primary-back-continue">
                    
                        <div class="back">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                (click)="onPrevious()"
                                class="back-button">
                                Previous</button>
                        </div>
                        <div class="continue-btn">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                class="epjTBn"
                                (click)="addBusinessDocument(mymodal2)">Save</button>
                        </div>
                    
                </div>

            </form>

        </div>
        <!--FORM CONTENT -->


    </div>

</div> <!-- RIGHT PANEL-->

<ng-template #mymodal2 let-modal>
    <div class="bgBody">
        <img class="image" src="http://54.216.113.184:8080/dao_business.png" alt="success" style="width: 100px;height: 100px;">
        <p>Thanks for providing business details!</p> 
        <p>Let's get your personal information.</p> 
        <div  class="centered">
            <button value="success" class="button button1" 
            (click)="modal.close('Save click')" >Continue</button>
        </div>
    </div>
  </ng-template>
</card-container>