import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpService } from '../sign-up/service/sign-up.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { BusinessDetailsComponent } from './components/business-details.component';
import {BusinessAddressComponent} from './components/business-address.component';
import {BusinessDocumentsComponent} from './components/business-documents.component';
import { StoreModule } from '@ngrx/store';
// import{reducer} from '../business-details/store/reducer/root.reducer'
import {businessFeatureKey,businessReducer} from '../../app/business-details/store/reducer/business.reducer';
import{businessAddressFeatureKey,businessAddressReducer} from '../../app/business-details/store/reducer/businessAddress.reducer'
import{businessDocumentFeatureKey,businessDocumentReducer} from '../../app/business-details/store/reducer/businessDocument.reducer'
import{businessOwnerFeatureKey,businessOwnerReducer} from '../../app/business-details/store/reducer/businessOwner.reducer'
import { BusinessAddressResolver } from './resolver/business-address.resolver';
import { NgxMaskModule } from 'ngx-mask';
import { UIModule } from '../util/ui/ui-module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';


@NgModule({
  declarations: [
    BusinessDetailsComponent,
    BusinessAddressComponent,
    BusinessDocumentsComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(businessFeatureKey,businessReducer),
    StoreModule.forFeature(businessAddressFeatureKey,businessAddressReducer),
    StoreModule.forFeature(businessDocumentFeatureKey,businessDocumentReducer),
    StoreModule.forFeature(businessOwnerFeatureKey,businessOwnerReducer),
    ReactiveFormsModule,
    AutocompleteLibModule,
    FormsModule,
    RouterModule,
    UIModule,
    NgxMaskModule.forRoot()
  ],
  providers:[SignUpService,BusinessAddressResolver],
  exports: [
    BusinessDetailsComponent,
    BusinessAddressComponent,
    BusinessDocumentsComponent
  ]
})
export class BusinessDetailsModule {}
