
<card-container>
    <div class="dyEAXN">
        <!-- RIGHT PANEL-->
        <div class="bCDrPV djdIYm">
            <div class="mjYRwVK">
                <!-- FORM CONTENT-->
                <div class="main-panel__content">
                    <div class="main-panel__content">
                        <h1 class="main-panel__heading nonreferral">Account
                            Information</h1>
                        <h2 class="main-panel__subheading">To get
                            started, please provide your work email and phone number</h2>
                    </div>
                </div>
    
                <form name="form" [formGroup]="accountForm">
                <div class="form__page sign-up__step-one">
                    <div class="form__group">
                        <div class="form__group">
                            <label data-component="form-input-email-label" for="email"
                            class="form__label">Work
                            Email Address</label>
                            <input id="userEmail" name="email" type="text" formControlName="userEmail"
                            [ngClass]="{'invalid': submitted &&  f.userEmail.errors }"
                            autocomplete="username" disabled=""
                            class="form__input validate-empty">
    
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="form__group">
                            <label data-component="form__label" for="name"
                            class="form__label">Full
                            Name <span style="color: red;">*</span></label>
                            <input id="name" formControlName="fullName"
                            [ngClass]="{'invalid': submitted &&  f.fullName.errors }" name="name"
                            type="text" class="form__input validate-empty" value="">
                            <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                                <div *ngIf="f.fullName.errors.required">Full Name is required</div>
                                <div *ngIf="f.fullName.errors?.pattern">Please provide a valid Name</div>
                            </div>
                        </div>
                    </div>
    
                    <div class="form__group">
                        <div class="form__group">
                            <label data-component="form__label" for="name"
                            class="form__label">Phone
                            Number <span style="color: red;">*</span></label>
                            <input id="name" formControlName="phoneNumber" [mask]="'999999999999999'" 
                            placeholder="XXXXXXXXXXXXXXX"
                                [ngClass]="{'invalid': submitted &&  f.phoneNumber.errors }"
                               type="text" class="form__input validate-empty">
                                <div *ngIf="submitted && f.phoneNumber.errors" class="text-danger">
                                    <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                                </div>
                            </div>
                    </div>
                    <div class="primary-back-continue">
                        
                            <div class="back">
                                <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                    (click)="onPrevious()"
                                    class="back-button">
                                    Previous</button>
                            </div>
                            <div class="continue-btn">
                                <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                    (click)="addAccount()"
                                    class="epjTBn">
                                    Continue</button>
                            </div>
                        
                        
                    </div>
                </div>
                </form>
    
            </div>
            <!--FORM CONTENT -->
    
    
        </div>
    
    </div>
</card-container>
 <!-- RIGHT PANEL-->