<card-container>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <!--<span class="main-panel__switch__text">Already have an account?</span>
                    <a class="main-panel__switch__button" id="login-sign-in-button"
                        href="loginaa64.html?redir=%2Fd%2FABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%2B%2F&amp;redirHash=">
                        Sign in
                    </a>-->
          <div class="main-panel__content">
            <h1 class="main-panel__heading nonreferral">
              Let's get borderless!
              <span class="main-panel__subheading">
                Accept payments from around the world
              </span>
            </h1>
          </div>

          <form name="form" [formGroup]="emailForm">
            <div class="form__group">
              <div class="form__group">
                <label for="email" class="form__label"
                  >Work Email Address</label
                >

                <input
                  type="email"
                  class="form__input validate-empty"
                  id="email"
                  formControlName="userEmail"
                  [ngClass]="{ invalid: submitted && f.userEmail.errors }"
                  placeholder="julie@widgetco.com"
                  autofocus
                  value=""
                />

                <!-- <div class="alert alert-error alert-email">
                                    Please enter a valid email address.
                                </div> -->
                <div
                  *ngIf="submitted && f.userEmail.errors"
                  class="text-danger"
                >
                  <div *ngIf="f.userEmail.errors.required">
                    Email Address is required
                  </div>
                  <div *ngIf="f.userEmail.errors?.pattern">
                    Please provide a valid Email Address
                  </div>
                </div>

                <div *ngIf="isError" class="text-danger">
                  <div>Email already registered</div>
                </div>
              </div>

              <div class="form__group" style="display: none">
                <label for="phone" class="form__label">Phone Number</label>

                <input
                  type="text"
                  class="form__input"
                  name="phone"
                  id="phone"
                  placeholder="Example (222) 555-1234 or +49 (351) 125-3456"
                  value=""
                />

                <div class="alert alert-error alert-phone">
                  Please enter a phone number.
                </div>
              </div>
            </div>

            <div class="eBKYjJ cgMkag">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                (click)="verifyEmail()"
              >
                Continue
              </button>
            </div>

            <div class="text--center" style="display: none">
              <button
                type="submit"
                class="form__button ea__prehide sign-up__submit"
              >
                Continue
              </button>
              <a
                class="form__button ea__redirect form__button--pink-accessible sign-up__submit"
                style="display: none"
              >
                Continue
              </a>
            </div>
            <p class="form__legal ea__prehide" style="display: none">
              By clicking "<span class="sign-up__cta-text">Continue</span>" I
              agree to InVision's
              <a
                href="http://www.invisionapp.com/terms_of_service"
                target="_blank"
                >Terms of Service</a
              >
              and
              <a href="https://www.invisionapp.com/privacy" target="_blank"
                >Privacy Policy</a
              >.
            </p>

            <!-- main-panel__content -->
          </form>
        </div>
      </div>
    </div>
  </div>
</card-container>
