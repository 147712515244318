import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {select, Store} from '@ngrx/store';
import {addPersonalDoc} from '../store/action/personalDocumentactions';
import {selectPersonalDocument} from '../store/selector/personalDocument.selectors'
import {selectBusiness} from '../../../app/business-details/store/selector/business.selectors'
import {selectPersonal} from '../../personal-details/store/selector/personal.selectors';
import {selectPersonalAddress} from '../../personal-details/store/selector/personalAddress.selectors';
import {selectCustomers} from '../../account-details/store/selector/customer.selectors';
import { BusinessCustomer } from '../model/businessCustomer';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {addPerson} from '../store/action/personal.actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Business } from 'src/app/business-details/model/business';
import { FormBuilder, Validators } from '@angular/forms';
import { Account } from 'src/app/account-details/model/account';
import { Document } from 'src/app/business-details/model/document';
import {PersonalDetailService} from '../service/personal-detail.service';
import { environment } from 'src/environments/environment';
import { Address } from 'src/app/business-details/model/address';

declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss']
})


export class PersonalDocumentsComponent implements OnInit{
  public business : Business;
  public isSuccess: boolean = false;
  public isError: boolean=false;
  public customer: BusinessCustomer;
  public document: string=null;
  public prevPersonal:BusinessCustomer;
  public prevPersonalAddr:Address;
  public prevPersonalDoc:any;
  public submitted = false;
  public previousResponse:any;
  selectedFile: ImageSnippet;
  public userDocument: Document[] = [];
  public previousBusinessResponse:any;
  public bDocument1:any;
  public bDocument2:any;
  public imageUrl:string;
  public closeResult: string;
  public prevCustomer:Account;


  personalDocumentsForm = this.formBuilder.group({
    front : ['', Validators.required],
    back  : ['', Validators.required]
  });
  get f(){return this.personalDocumentsForm.controls};


  constructor(private router: Router,private store: Store<Document>,private modalService: NgbModal,
    private ngxService: NgxUiLoaderService,
    private formBuilder : FormBuilder,private personalDetailService:PersonalDetailService){

      this.imageUrl=environment.api_base_url;

      this.store.pipe(select(selectBusiness)).subscribe((result) => {
        this.previousBusinessResponse=result;
      })

      this.store.pipe(select(selectCustomers)).subscribe((result:any) => {
        this.prevCustomer=result;
       });

       this.store.pipe(select(selectPersonal)).subscribe((result:any) => {
        this.prevPersonal=result;
       });

       this.store.pipe(select(selectPersonalDocument)).subscribe((result:any) => {
        this.prevPersonalDoc=result;
      })

       this.store.pipe(select(selectPersonalAddress)).subscribe((result:any) => {
         this.prevPersonalAddr=result;
       });

      // this.store.pipe(select(selectPersonalDocument)).subscribe((result:any) => {
      //   this.previousResponse=result;
      //  })
    }

  ngOnInit() {
      if(this.previousResponse){
        this.bDocument1=this.previousResponse[0].document;
        this.bDocument2=this.previousResponse[1].document;
      }
  }


  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.sendImageApi(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }

  sendImageApi(file){
    this.ngxService.start();
    this.personalDetailService.sendPersonalDocument(file ,"ID", "Nic").subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.document = result.responseData.Date.document;
        let nic = new Document(null, "Document","Nic", this.document, 1);
         this.userDocument.push(nic);
         this.ngxService.stop();
      }
      else {
        this.isError = true;
        this.ngxService.stop();
      }selectPersonalDocument
    }, error => { console.log(error) })
  }

  // sendImageApi(file){
  //   this.ngxService.start();
  //   this.personalDetailService.sendPersonalDocument(file).subscribe((result) => {
  //     if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
  //       this.document=result.responseData.fileID;
  //       let nic = new Document(null, "Document","Nic", this.document, 1);
  //        this.userDocument.push(nic);
  //        this.ngxService.stop();
  //     }
  //     else {
  //       this.isError = true;
  //       this.ngxService.stop();
  //     }selectPersonalDocument
  //   }, error => { console.log(error) })
  // }

  addUserDocument(){
    this.submitted = true;
      if (this.personalDocumentsForm.invalid){
        return;
      }
    else{

        this.store.dispatch(addPersonalDoc(this.userDocument));
        this.router.navigate(['/bioVerification']);
    }
  }



  onPrevious(){
    this.router.navigate(['/personalDoctype']);
  }



}
