import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { log } from 'console';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  // Inside your component class
  currentStep: number = 1; // Set the initial step
  currentPage: any;

  constructor(private router: Router) { }
  
  ngOnInit(): void {
    this.router.events.subscribe((event: NavigationEvent) => {
			if(event instanceof NavigationStart) {
			this.currentPage = event.url;
      if (this.currentPage.includes('?')) { 
        const urlParts = this.currentPage.split('?');
        this.currentPage = urlParts[0];
      }
        }
        console.log(this.currentPage);
        if(this.currentPage == '/emailCheck' || this.currentPage == '/accountDetails' ||
            this.currentPage == '/verifyOTP') {
            this.currentStep = 1
      } else if(this.currentPage == '/businessDetails' || this.currentPage == '/businessAddress' ||
                this.currentPage == '/businessDocuments'){
                this.currentStep = 2
      } else if(this.currentPage == '/personalDetails' || this.currentPage == '/personalAddress' ){
                this.currentStep = 3
      } else{ 
        this.currentStep = 1
      }
    });
  }
  ngAfterViewInit(){
   
  }
}
