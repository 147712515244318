import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenricateService } from 'src/app/email-check/service/authenricate.service';
declare var $: any;

@Component({
  selector: 'create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})

export class CreateAccountComponent {

  public redirectUrl = environment.portal_redirect_url;
  public email = localStorage.getItem('userEmail')

  constructor(private service: AuthenricateService) {  }

  routeToBusinessPortal(){
    const url = this.redirectUrl
      window.location.href = `${url}?path=onboard`;
  }

  updateOnboardingFlag() {
    let body = {
      email: this.email
    }
    this.service.updateOnboardingFlag(body).subscribe((resp: any) =>{
      console.log(resp);
      
      this.routeToBusinessPortal();
    });
  }

}