<card-container>
    <div class="dyEAXN">
        <!-- RIGHT PANEL-->
        <div class="bCDrPV djdIYm">
            <div class="mjYRwVK">
                <!-- FORM CONTENT-->
                <!-- <div class="hnZgAZ"> -->
                <!-- <div class="husZCp"> -->

                <div class="main-panel__content">
                    <div class="main-panel__content">
                        <h1 class="main-panel__heading nonreferral">
                            Business Address Details</h1>
                        <h2 class="main-panel__subheading">Please
                            provide your business address details</h2>
                    </div>

                </div>
                <!-- </div> -->
                <!-- </div> -->

                <!-- <br> -->

                <div class="row">
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="address" [searchKeyword]="keyword" placeholder="Search Address"
                            (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                            (inputFocused)='onFocused($event)' historyIdentifier="address" [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.text + ' - ' + item.description + ' - ' + item.type "></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <label for="team_size" class="form__label">Select Address <span
                                style="color: red;">*</span></label>
                        <select class="form__select" [(ngModel)]="newSelectedAddress"
                            (ngModelChange)="onSelectAddress($event)">
                            <option *ngFor="let add of addressArray" [ngValue]="add">{{add.text}}</option>
                        </select>
                    </div>
                </div>

                <form name="addressForm" [formGroup]="addressForm">
                    <div class="row">
                        <div class="col-sm-5">
                            <label data-component="form-input-email-label" for="email" class="form__label">Street Name
                                <span style="color: red;">*</span></label>
                            <input id="line1" type="text" placeholder="" formControlName="line1"
                                [ngClass]="{'invalid': submitted &&  f.line1.errors }" autocomplete="username"
                                class="form__input validate-empty" value="">
                            <div *ngIf="submitted && f.line1.errors" class="text-danger">
                                <div *ngIf="f.line1.errors.required">Street Name is required</div>
                                <div *ngIf="f.line1.errors.minlength">Required atleast 6 Characters</div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <label data-component="form-input-name-label" class="form__label" for="name">Address
                                Line</label>
                            <input id="line2" formControlName="line2" type="text" class="form__input validate-empty"
                                value="">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-5">
                            <label for="team_size" class="form__label">Country <span
                                    style="color: red;">*</span></label>

                            <input id="country" formControlName="country" type="text" class="form__input validate-empty"
                                value="">

                            <!-- <select id="country" formControlName="country"
                        [ngClass]="{'invalid': submitted &&  f.country.errors }"
                        class="form__select">
               
                        <option *ngFor="let countrty of countries" [value]="countrty.id">{{countrty.name}}
                        </option>
                    </select> -->
                            <div *ngIf="submitted && f.country.errors" class="text-danger">
                                <div *ngIf="f.country.errors.required">Country is required</div>
                            </div>
                        </div>

                        <div class="col-sm-5">
                            <label for="team_size" class="form__label">State <span style="color: red;">*</span></label>
                            <input id="state" formControlName="state" type="text" class="form__input validate-empty"
                                value="">
                            <!-- <select id="state" formControlName="state"
                        [ngClass]="{'invalid': submitted &&  f.state.errors }"
                        class="form__select">
                        <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                    </select> -->
                            <div *ngIf="submitted && f.state.errors" class="text-danger">
                                <div *ngIf="f.state.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm-5">
                            <label for="team_size" class="form__label">City <span style="color: red;">*</span></label>
                            <input id="city" formControlName="city" type="text" class="form__input validate-empty"
                                value="">
                            <!-- <select id="city" formControlName="city"
                        [ngClass]="{'invalid': submitted &&  f.city.errors }"
                        class="form__select">
        
                        <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
                    </select> -->
                            <div *ngIf="submitted && f.city.errors" class="text-danger">
                                <div *ngIf="f.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <label data-component="form-input-name-label" for="name" class="form__label">Postcode <span
                                    style="color: red;">*</span></label>
                            <input id="postalCode" type="text" formControlName="postalCode"
                                [ngClass]="{'invalid': submitted &&  f.postalCode.errors }"
                                class="form__input validate-empty" value="">
                            <div *ngIf="submitted && f.postalCode.errors" class="text-danger">
                                <div *ngIf="f.postalCode.errors.required">Postal Code is required</div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="primary-back-continue">

                        <div class="back">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                (click)="onPrevious()" class="back-button">
                                Previous</button>
                        </div>
                        <div class="continue-btn">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                class="epjTBn" (click)="addBusinessAddress()">Continue</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div> <!-- RIGHT PANEL-->
</card-container>