<div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
        <div class="jYRwVK">
            <!-- FORM CONTENT-->
            <div class="hnZgAZ">
                <div class="husZCp">
                    <h1 data-component="heading"
                        class="styles__Root-sc-1myek4t-0 fdExrC Heading__Head-junqdu-1 lgVgrq">Verify your
                        Identity</h1>
                    <h2 data-component="subheading"
                        class="styles__Root-sc-1myek4t-0 cclCHJ Heading__SubHead-junqdu-2 goFGMk">Please
                        provide front and back scanned images of your National Identity Card</h2>
                </div>
            </div>

            <form name="f" [formGroup]="personalDocumentsForm">
                <div>
                    <label data-component="form-input-email-label" for="email"
                        class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy">National
                        Identity Card (FRONT SIDE)</label>
                    <div class="NecEC">
                        <div class="NAEPS">
                            <div class="jvubth">
                                <input name="front" type="file" placeholder="" #perImageFront
                                    (change)="processFile(perImageFront)" formControlName="front"
                                    [ngClass]="{'invalid': submitted &&  f.front.errors }"
                                    class="styles__Root-sc-1dahuaq-1 eWmkGc" value="">
                            </div>
                            <div *ngIf="submitted && f.front.errors" class="text-danger">
                                <div *ngIf="f.front.errors.required">National
                                    Identity Card (FRONT SIDE) is required</div>
                            </div>
                            <div>Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are allowed</div>
                        </div>
                    </div>
                </div>
                <div>
                    <label data-component="form-input-email-label" for="email"
                        class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy">National
                        Identity Card (BACK SIDE)</label>
                    <div class="NecEC">
                        <div class="NAEPS">
                            <div class="jvubth">
                                <input name="back" type="file" placeholder="" #perImageBack
                                    (change)="processFile(perImageBack)" formControlName="back"
                                    [ngClass]="{'invalid': submitted &&  f.back.errors }"
                                    class="styles__Root-sc-1dahuaq-1 eWmkGc" value="">
                            </div>
                            <div *ngIf="submitted && f.back.errors" class="text-danger">
                                <div *ngIf="f.back.errors.required">National Identity Card (BACK SIDE) is required</div>
                            </div>
                            <div>Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are allowed</div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div *ngIf="bDocument1" class="input-field col s4">
                        <label for="bDocument1" class="active">Document 1</label>
                        <img id="myimage" src="{{imageUrl}}file/view/{{bDocument1}}" />
                        <!-- <input type="text" formControlName="bDocument1" class="form-control" id="bDocument1" /> -->
                    </div>
                      <div *ngIf="bDocument2" class="input-field col s4">
                        <label for="bDocument2" class="active">Document 2</label>
                        <img id="myimage"src="{{imageUrl}}file/view/{{bDocument2}}" />
                        <!-- <input type="text" formControlName="bDocument2" class="form-control" id="bDocument2" /> -->
                      </div>
                </div>
                
                <div class="eBKYjJ cgMkag">
                    <div class="row">
                        <div class="input-field col s4">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                (click)="onPrevious()"
                                class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn">
                                Previous</button>
                        </div>
                        <div class="input-field col s4">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                                (click)="addUserDocument()">Continue</button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
        <!--FORM CONTENT -->


    </div>

</div> <!-- RIGHT PANEL-->


<!-- <ng-template #mymodal3 let-modal>
    <div class="bgBody">
        <img class="image" src="../../../assets/success.jpg" alt="success">
        <p>Thank you for providing personal information!</p> 
        <p>Let's take your selfie now</p>  
        <div class="container">
            <div class="center">
        <button value="success" class="button button1" (click)="modal.close('Save click')">Continue</button>
            </div>
        </div>
    </div>
  </ng-template> -->