
<card-container>
    <div class="dyEAXN">
        <!-- RIGHT PANEL-->
        <div class="bCDrPV djdIYm">
            <div class="mjYRwVK">
                       <div  class="main-panel__content">
                        <div  class="main-panel__content">
                            <h1  class="main-panel__heading nonreferral">
                                Personal Address Information</h1>
                            <h2 class="main-panel__subheading">Please
                                provide your personal address details</h2>
                        </div>
                       </div>

                <!-- <div class="row">
                <div class="col-sm-5">
                        <label data-component="form-input-email-label" class="form__label" for="searchText">Search address <span style="color: red;">*</span></label>
                            <input id="text" name="text" type="text"  [(ngModel)]="searchText"
                            class="form__input validate-empty" value="">         
                </div>
                <div class="col-sm-5">
                    <button (click)="search()">Search</button>
                </div>
       
            </div> -->
            <!-- <div class="row">
                <div class="col-sm-5">
                    <label for="team_size" class="form__label"> <span style="color: red;">*</span></label>
                    <select name="team_size" id="team_size" class="form__select" *ngIf="addressArray.length > 0">
                        <option *ngFor="let option of addressArray" [value]="option.id">{{ option.text }}</option>
                      </select>
                </div>
            </div>                 -->
        
    <!-- <br> -->
            <form name="form" [formGroup]="personalDetailForm">
                <div class="row">
                    <div class="col-sm-5">
                            <label data-component="form-input-email-label" class="form__label" for="email">Street Name <span style="color: red;">*</span></label>
                                <input id="text" name="text" type="text" formControlName="line1"
                                        [ngClass]="{'invalid': submitted &&  f.line1.errors }"
                                        class="form__input validate-empty" value="">
                                        <div *ngIf="submitted && f.line1.errors" class="text-danger">
                                            <div *ngIf="f.line1.errors.required">Street Name is required</div>
                                            <div *ngIf="f.line1.errors.minlength">Required atleast 6 Characters</div>
                                        </div>
                    </div>
                    <div class="col-sm-5">
                            <label data-component="form-input-name-label" class="form__label" for="name">Address Line</label>
                           <input id="name" formControlName="line2" name="name" type="text" autocomplete="name"
                            class="form__input validate-empty" value="">
                    </div>
                </div>
                    
                <div class="row">
                    <div class="col-sm-5">
                            <label for="team_size" class="form__label">Country <span style="color: red;">*</span></label>
                            <select name="team_size" formControlName="country"
                            [ngClass]="{'invalid': submitted &&  f.country.errors }" id="team_size"
                            class="form__select">
                            <option *ngFor="let countrty of countries" [value]="countrty.id">{{countrty.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.country.errors" class="text-danger">
                            <div *ngIf="f.country.errors.required">Country is required</div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                            <label for="team_size" class="form__label">State <span style="color: red;">*</span></label>
                            <select id="state" formControlName="state"
                            [ngClass]="{'invalid': submitted &&  f.state.errors }"
                            class="form__select">
                            <!-- <option value="" disabled selected>From which state you are doing business?
                            </option> -->
                            <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.state.errors" class="text-danger">
                            <div *ngIf="f.state.errors.required">State is required</div>
                        </div>
                    </div>
                </div>
                   
                 
                   
                    
                <div class="row">
                    <div class="col-sm-5">
                            <label for="team_size" class="form__label" >City<span style="color: red;">*</span></label>
                            <select id="city" formControlName="city"
                            [ngClass]="{'invalid': submitted &&  f.city.errors }"
                            class="form__select">
                            <!-- <option value="" disabled selected>From which city you are doing business?
                            </option> -->
                            <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.city.errors" class="text-danger">
                            <div *ngIf="f.city.errors.required">City is required</div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <label data-component="form-input-name-label" class="form__label" for="name">Postcode <span style="color: red;">*</span></label>
                        <input id="name" formControlName="postalCode"
                        [ngClass]="{'invalid': submitted &&  f.postalCode.errors }" name="name" type="text"
                        autocomplete="name" class="form__input validate-empty" value="">
                        <div *ngIf="submitted && f.postalCode.errors" class="text-danger">
                            <div *ngIf="f.postalCode.errors.required">Postal Code is required</div>
                        </div>
                    </div>
                </div>
                    
                        
                       
                <div class="row">
                    <div class="primary-back-continue">
                       <div class="back">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                (click)="onPrevious()"
                                class="back-button">
                                Previous</button>
                        </div>
                        <div class="continue-btn">
                            <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                class="epjTBn"
                                (click)="addPersonalAddress(mymodal3)">Save</button>
                        </div>
                    </div>
                </div>
                    
    
            </form>
    
            </div>
            <!--FORM CONTENT -->
        </div>
    </div>
    <ng-template #mymodal3 let-modal>
        <div class="bgBody">
            <img class="image" src="http://54.216.113.184:8080/dao_personal.png" alt="success"
                style="width: 100px;height: 100px;">
            <p>Thank you for providing personal information!</p>
            <p>Let's get your Identification Documents for Verification</p>
            <div class="centered">
                <button value="success" class="button button1" (click)="modal.close('Save click')">Continue</button>
            </div>
            <!-- <div class="container">
                <div class="center">
                </div>
            </div> -->
            <!-- <button type="button" class="btn btn-outline-dark" >Ok</button>      -->
        </div>
    </ng-template>
</card-container>
 <!-- RIGHT PANEL-->

