<card-container>

    <div class="dyEAXN">
        <div class="bCDrPV djdIYm">
            <div class="mjYRwVK">
                <div  class="main-panel__content">
                    <div  class="main-panel__content">
                        <h1  class="main-panel__heading nonreferral">
                            Personal Information</h1>
                        <h2 class="main-panel__subheading">Please provide your personal details</h2>
                    </div>
                </div>
                <form name="form" [formGroup]="personalDetailForm">
                    <div class="row">
                        <div class="col-sm-11">
                            <label data-component="form-input-name-label" class="form__label"  for="email">First Name <span style="color: red;">*</span></label>
                            <input id="email" name="email" type="text" placeholder="" formControlName="firstName"
                                [ngClass]="{'invalid': submitted &&  f.firstName.errors }"
                                class="form__input validate-empty" value="">
                            <div *ngIf="submitted && f.firstName.errors" class="text-danger">
                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                <div *ngIf="f.firstName.errors.minlength">Required atleast 3 Characters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5">
                            <label data-component="form-input-name-label" class="form__label" for="name">Last Name <span style="color: red;">*</span></label>
                            <input id="name" formControlName="lastName"
                                [ngClass]="{'invalid': submitted &&  f.lastName.errors }" name="name" type="text"
                                autocomplete="name" class="form__input validate-empty" value="">
                            <div *ngIf="submitted && f.lastName.errors" class="text-danger">
                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                <div *ngIf="f.lastName.errors.minlength">Required atleast 3 Characters</div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <label data-component="form-input-name-label" class="form__label" for="name">Date Of Birth <span style="color: red;">*</span></label>
                            <input id="name" name="name" type="date" autocomplete="name" formControlName="dateOfBirth"
                                [ngClass]="{'invalid': submitted &&  f.dateOfBirth.errors }"
                                class="form__input" value="">
                            <div *ngIf="submitted && f.dateOfBirth.errors" class="text-danger">
                                <div *ngIf="f.dateOfBirth.errors.required">DOB is required</div>
                                <div *ngIf="f.dateOfBirth.errors.minAge">Age limit is 18 years old</div>
                            </div>
                        </div>
                    </div>
                   
                       
                   
                
                        
                   
                    <div class="row">
                        <div class="col-sm-5">
                            <label for="team_size" class="form__label">Gender <span style="color: red;">*</span></label>
                            <select name="team_size" id="team_size" formControlName="gender"
                                [ngClass]="{'invalid': submitted &&  f.gender.errors }"
                                class="form__select">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <div *ngIf="submitted && f.gender.errors" class="text-danger">
                                <div *ngIf="f.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <label for="team_size" class="form__label" >Nationality <span
                                    style="color: red;">*</span></label>
                            <select name="team_size" id="team_size" formControlName="nationality"
                                [ngClass]="{'invalid': submitted &&  f.nationality.errors }"
                                class="form__select">
                                <!-- <option value="" disabled selected>From which country you belong?
                                </option> -->
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Singapore">Singapore</option>
                                <option value="India">India</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Nepal">Nepal</option>
                            </select>
    
                            <div *ngIf="submitted && f.nationality.errors" class="text-danger">
                                <div *ngIf="f.nationality.errors.required">Country is required</div>
                            </div>
                        </div>
                    </div>
    
                
                        <div class="row">
                            <!-- <div class="input-field col s4">
                                <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                    (click)="onPrevious()"
                                    class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn">
                                    Previous</button>
                            </div> -->
                            <div class="eBKYjJ cgMkag">
                                <button data-component="primary-button" type="submit" role="button" tabindex="0"
                                    class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                                    (click)="addPersonalDetail()">Continue</button>
                            </div>
                        </div>
                    
    
                </form>
            </div>
        </div>
    </div>
</card-container>


 <!-- RIGHT PANEL-->