import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { addBusiAddr } from '../store/action/businessAddress.actions';
import { Business } from '../model/business';
import { selectBusinessAddress } from '../store/selector/businessAddress.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '../model/address'
import { Data } from 'src/app/providers/data';
import { BusinessDetailService } from '../service/business-detail.service';

declare var $: any;

@Component({
  selector: 'business-address',
  templateUrl: './business-address.component.html',
  styleUrls: ['./business-address.component.scss']
})

export class BusinessAddressComponent implements OnInit {
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public business: Business;
  public submitted = false;
  public states: any;
  public countries: any;
  public cities: any;
  public previousResponse: any;
  public address: any;
  addressArray: any[] = [];
  selectedAddress: any;
  newSelectedAddress: any

  addressForm = this.formBuilder.group({
    line1: ['', [Validators.required, Validators.minLength(6)]],
    line2: [''],
    postalCode: ['', Validators.required],
    city: ['', Validators.required],
    state: ['', Validators.required],
    country: ['', Validators.required]
  });

  get f() { return this.addressForm.controls };

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<Address>, private data: Data,
    private formBuilder: FormBuilder, private service: BusinessDetailService) {

    this.route.data.subscribe(response => {
      let res = response[0].countries;
      if (res.code === 'DAO_SUCCESS_00') {
        this.countries = res.responseData.countries;
      }
    })

    this.store.pipe(select(selectBusinessAddress)).subscribe((result) => {
      this.previousResponse = result;
    })
  }



  ngOnInit() {
    if (this.previousResponse) {
      this.addressForm.patchValue(this.previousResponse);
    }


    this.addressForm.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
    });

    this.addressForm.get('state').valueChanges.subscribe(val => {
      this.fetchCities(val);
    });
  }


  fetchCities(stateId) {
    this.states.map(x => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  addBusinessAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    else {
      const businessAddress = new Address(null, this.addressForm.value.line1,
        this.addressForm.value.line2, this.addressForm.value.city, this.addressForm.value.postalCode,
        this.addressForm.value.state, this.addressForm.value.country);
      this.store.dispatch(addBusiAddr(businessAddress))
      this.router.navigate(['/businessDocuments']);
    }
  }

  onPrevious() {
    const navigationExtras = {
      queryParams: { paramKey: 'previous' },
    };

    this.router.navigate(['/businessDetails'], navigationExtras);
  }

  keyword = 'text';

  selectEvent(item) {
    // do something with selected item
    console.log(item);
    let body = {
      address: item.id,
      limit: 10,
      countries: "GB"
    }
    this.service.fetchSubAddress(body).subscribe((resp: any) => {
      if (resp.code === "DAO_SUCCESS_00") {
        this.addressArray = resp.responseData.Data.items;
      } else {

      }
    });
  }

  fetchAddress(search: string) {
    let body = {
      address: search,
      limit: 10,
      countries: "GB"
    }
    this.service.fetchAddress(body).subscribe((resp: any) => {
      if (resp.code == "DAO_SUCCESS_00") {
        if (resp.responseData.Data != null) {
          this.address = resp.responseData.Data.items;
        }
      } else {

      }
    });
  }

  onChangeSearch(search: string) {
    console.log(search);
    if (search) {
      this.fetchAddress(search);
    }
  }

  onSelectAddress(address: any) {
    let body = {
      address: address.id
    };

    this.service.getAddressDetail(body).subscribe((response: any) => {
      console.log(response);
      if (response.code === "DAO_SUCCESS_00") {
        const newSelectedAddress = response.responseData?.Data?.items[0];
        console.log(newSelectedAddress);

        if (newSelectedAddress) {
          this.selectedAddress = { id: newSelectedAddress.id, text: newSelectedAddress.text || 'N/A' };

          this.addressForm.setValue({
            line1: newSelectedAddress.street || '',
            line2: newSelectedAddress.line1 || '',
            country: newSelectedAddress?.countryName || '',
            state: newSelectedAddress.district || '',
            city: newSelectedAddress.city || '',
            postalCode: newSelectedAddress.postalCode || ''
          });

          console.log('Updated Selected Address:', this.selectedAddress);
        } else {
          console.error('newSelectedAddress is undefined.');
        }
      } else {
        console.error('Failed to fetch address details. Response code:', response.code);
        // Handle the case when the response code is not "DAO_SUCCESS_00"
      }
    });
  }



  // onSelectAddress(address: any) {
  //   let body = {
  //     address: address.id
  //   }
  //   this.service.getAddressDetail(body).subscribe((response: any) => {
  //     console.log(response);
  //     if (response.code === "DAO_SUCCESS_00") {
  //       this.selectedAddress = response.responseData?.Data?.items[0]
  //       this.addressForm.patchValue({
  //         line1: this.selectedAddress.street,
  //         line2: this.selectedAddress.line1,
  //         postalCode: this.selectedAddress.postalCode,
  //         country: this.selectedAddress?.countryName,
  //         city: this.selectedAddress.city,
  //         state: this.selectedAddress.district
  //       });
  //     } else {

  //     }
  //   });
  // }



  onFocused(e) {
    // do something
  }


}