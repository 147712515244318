import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../../sign-up/system/system.constants';
import { HttpService } from '../../http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class BusinessDetailService {

  constructor(private httpService:HttpService,
    private ngxLoader: NgxUiLoaderService) { }

  private baseUrl = environment.api_base_url;
  private headers = SystemConstants.header;

  // Old method
  // sendDocument(file: File): Observable<any> {
  //   let formData:FormData = new FormData();
  //   formData.append('file', file);
  //   return this.httpService.post(this.baseUrl+"file/addFile",formData)
  //   .pipe(map((res:any) => {
  //     this.ngxLoader.stop();
  //     return JSON.parse(res._body);
  //   })).pipe(catchError((error:any) => {
  //     console.log(error);
  //     this.ngxLoader.stop();
  //     return error;
  //   }));
  // }

  sendDocument(file: File, type: any, indentity: any): Observable<any> {
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('identity', indentity);
    return this.httpService.post(this.baseUrl+"documentUpload/upload",formData)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    }));
  }


  getSelectedBusiness(id): Observable<any> {
    return this.httpService.get(this.baseUrl+`business/search?id=${id}`)
          .pipe(map((res: any) => {
            // return JSON.parse(res._body);
            return res.json();
          })).pipe(catchError((error:any) => {
            console.log(error);
            return error;
    }));
  }

  /*
* get all active industries
*/
getActiveIndustries(): Observable<any> {

  return this.httpService.get(this.baseUrl + "industry/list")
    .pipe(map((res: any) => {
      return res.json();
    })).pipe(catchError((error: any) => {
      console.log(error);
      return error;
    }));
}

 /*
* get all active industries
*/
getRegistrationType(): Observable<any> {
  return this.httpService.get(this.baseUrl + "registrationType/list")
    .pipe(map((res: any) => {
      return res.json();
    })).pipe(catchError((error: any) => {
      console.log(error);
      return error;
    }));
}


fetchCountries(): Observable<any> {
  return this.httpService.get(this.baseUrl + "country/list")
    .pipe(map((res: any) => {
      return res.json();
    })).pipe(catchError((error: any) => {
      console.log(error);
      return error;
    }));
  }

  verifyBusinesName(body: any): Observable<any> {
    // let headers = new Headers();
    // headers.append("Content-Type","application/json");
    return this.httpService.post(this.baseUrl+"companyCheck/companySearchNameMatch",body)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  
  fetchAddress(body: any): Observable<any> {
    // let headers = new Headers();
    // headers.append("Content-Type","application/json");
    return this.httpService.post(this.baseUrl+"loqate/findAddressCaptureV1",body)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  fetchSubAddress(body: any): Observable<any> {
    // let headers = new Headers();
    // headers.append("Content-Type","application/json");
    return this.httpService.post(this.baseUrl+"loqate/findAddressCaptureV1",body)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  getAddressDetail(body: any): Observable<any> {
    // let headers = new Headers();
    // headers.append("Content-Type","application/json");
    return this.httpService.post(this.baseUrl+"loqate/retrieveAddressCaptureV2",body)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  checkEmailAndPhoneExist(requestBody: any): Observable<any> {

    return this.httpService.post(this.baseUrl+'customer/isStackHolderAlreadyExist', requestBody)
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

}
