import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PersonalDetailService } from '../personal-details/service/personal-detail.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rewiew-documents',
  templateUrl: './rewiew-documents.component.html',
  styleUrls: ['./rewiew-documents.component.scss']
})
export class RewiewDocumentsComponent implements OnInit {

    public previosData: any;
    public documentInfos: any = [];
    public verificationId: any;
    public documentsData: any;
    public phoneNumber: any;
    public submitted = false;
    dataArray: any[] = [];
    public paramValue: any;
    public isNotPassport: boolean = false;
    public nationality: any;
    public firstName: any;
    public lastName: any;
    public dateOfBirth: any;
    public Category: any;
    public docCategory: any;
    public idNumber: any;
    public isNotId: boolean = false;
    public name: any;
    public businessID: any = localStorage.getItem('businessID');
    public digibase_url: any = environment.digibase_url;

  informationForm = this.formBuilder.group({
    add1: new FormControl('', ),
    city: new FormControl('',),
    expiry: new FormControl('', Validators.required),
    postTown: new FormControl(''),
    postCode: new FormControl(''),
    email: new FormControl('', [
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      Validators.required,
    ]),
    country: new FormControl(''),
    dob: ['',],
    expectedSpend: new FormControl(''),
    check: new FormControl(''),
    address: new FormControl(''),
    resCountry: new FormControl(''),
  });

  get f() {
    return this.informationForm.controls;
  }

  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    // private service: DocumentsService,
    private ngxLoader: NgxUiLoaderService,
    // private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private service: PersonalDetailService
  ) { }

  ngOnInit() {
    this.phoneNumber = localStorage.getItem('userPhone');
    console.log("phone number", this.phoneNumber);


    console.log(localStorage.getItem('userPhone'));
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.paramValue = params['JourneyId'];
      console.log(this.paramValue);
    });
    const params = new HttpParams().set('JourneyId', this.paramValue);
    let body = {
      journeyId: this.paramValue,
      username: this.phoneNumber
    };
    console.log(body);
    this.http.post(this.digibase_url+'idscan/retrieve_uploaded_document_data',body).subscribe(
        (data: any) => {
          let res = data.extractedFieldDataList;
          localStorage.setItem('remittorId', '1925');
          if (data.idscanValidationStatus === true) {
            console.log(res);
            res.forEach((dealer: { name: any; value: any }) => {
              if (dealer.name === 'DocumentCategory') {
                this.docCategory = dealer.value;
              }
            });
            console.log(this.docCategory);

            if (this.docCategory === 'National Identification Card') {
              this.isNotPassport = true;
              res.forEach((dealer: { name: any; value: any }) => {
                if (dealer.name === 'FirstName') {
                  this.firstName = dealer.value;
                }
                if (dealer.name === 'LastName') {
                  this.lastName = dealer.value;
                }
                if (dealer.name === 'BirthDate') {
                  this.dateOfBirth = dealer.value;
                }
                if (dealer.name === 'NationalityName') {
                  this.nationality = dealer.value;
                }
                if (dealer.name === 'DocumentCategory') {
                  this.Category = dealer.value;
                }
                if (dealer.name === 'PersonalNumber') {
                  this.idNumber = dealer.value;
                }
                if (dealer.name === 'ExpiryDate') {
                  this.informationForm.controls.expiry.setValue(dealer.value);
                }
              });
            } else if (this.docCategory === 'Passport') {
              this.isNotId = true;
              res.forEach((dealer: { name: any; value: any }) => {
                if (dealer.name === 'FirstName') {
                  this.firstName = dealer.value;
                }
                if (dealer.name === 'LastName') {
                  this.lastName = dealer.value;
                }
                if (dealer.name === 'BirthDate') {
                  this.dateOfBirth = dealer.value;
                }
                if (dealer.name === 'NationalityName') {
                  this.nationality = dealer.value;
                }
                if (dealer.name === 'DocumentCategory') {
                  this.Category = dealer.value;
                }
                if (dealer.name === 'DocumentNumber') {
                  this.idNumber = dealer.value;
                }
              });
            } else {
            }
          } else {
            console.log('false');
            // this.modal.open(this.modalContent);
          }
        },
        (error: any) => {
          // Handle any errors that occurred during the request
          console.error('Error:', error);
        }
      );
    // }

    this.verificationId = this.previosData.gbgReponse.responseData.GlobalResultData.guid;
    this.previosData.documentVos.forEach((item: any) => {
      this.documentInfos.push({
        path: item.path,
        fileName: item.fileName,
        uploadedDate: item.uploadedDate,
        type: item.type,
      });
    });
    this.documentsData = {
      documentInfo: this.documentInfos,
    };
  }

  saveData() {
    this.submitted = true;

    if (this.informationForm.invalid) {
      return;
    } else {
      let body = {
        id: null,
        firstName: this.firstName,
        lastName: this.lastName,
        dateofbirth: this.dateOfBirth,
        nationality: this.nationality,
        idNumber: this.idNumber,
        expiry: this.informationForm.controls.expiry.value,
        email:  this.informationForm.controls.email.value,
        address:  this.informationForm.controls.address.value,
        town: this.informationForm.controls.postTown.value,
        postalCode: this.informationForm.controls.postCode.value,
        country: this.informationForm.controls.resCountry.value,
        businessId: this.businessID
      }
      this.service.saveIdScanData(body).subscribe((resp: any) =>{
        console.log(resp);
        if(resp.code === 'DAO_SUCCESS_00') {
          this.customerNotification(this.businessID);
          this.route.navigate(['/createAccount']);
        }
      });
    }
  }

customerNotification(id : any){
  this.service.sendCustomerNotification(id).subscribe((resp: any)=>{
    console.log(resp);    
  });
}



}
