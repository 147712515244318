import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Business } from '../model/business';
import {selectCustomers} from '../../account-details/store/selector/customer.selectors';
import { Document } from '../model/document'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import { SignUpService } from '../../sign-up/service/sign-up.service';
import {selectBusinessDocument} from '../store/selector/businessDocument.selectors';
import {selectBusinessOwner} from '../store/selector/businessOwner.selectors copy';
import {selectBusiness} from '../../business-details/store/selector/business.selectors';
import {selectBusinessAddress} from '../../business-details/store/selector/businessAddress.selectors';
import {addBusDocument} from '../store/action/businessDoc.actions';
import {BusinessDetailService} from '../service/business-detail.service'
import { environment } from '../../../../src/environments/environment';
import { Address } from '../model/address';
import {addBusiness} from '../store/action/business.actions';
import { Account } from 'src/app/account-details/model/account';


declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'business-documents',
  templateUrl: './business-documents.component.html',
  styleUrls: ['./business-documents.component.scss']
})


export class BusinessDocumentsComponent implements OnInit {
  public isSuccess: boolean = false;
  public isError: boolean=false;
  public submitted = false;
  public previousResponse:any;
  public businessStakeholder:any;
  public business: Business;
  public accDetail:Account;
  public prevBusiness:Business
  public prevBusinessAddr:Address;
  public document: string;
  public businessDocument=[];
  public bDocument1:any;
  public bDocument2:any;
  public imageUrl:string;
  selectedFile: ImageSnippet;
  public closeResult: string;
  public prevCustomer:Account;

  documentsForm = this.formBuilder.group({
    businessRegistrationDocument : ['', Validators.required],
    ntnDocument: ['', Validators.required]
  });
  get f(){return this.documentsForm.controls};


  constructor(private router: Router,private store: Store<Document>,private signUpService:SignUpService,
    private modalService: NgbModal,private ngxService: NgxUiLoaderService,
    private businessDetailService:BusinessDetailService,private formBuilder : FormBuilder) {
      this.imageUrl=environment.api_base_url;

      this.store.pipe(select(selectCustomers)).subscribe((result:any) => {
        this.prevCustomer=result;
       });

      this.store.pipe(select(selectBusiness)).subscribe((result:any) => {
        this.prevBusiness=result;
        });
        this.store.pipe(select(selectBusinessAddress)).subscribe((result:any) => {
          this.prevBusinessAddr=result;
         });

      this.store.pipe(select(selectBusinessDocument)).subscribe((result:any) => {
        this.previousResponse=result;
       });

       this.store.pipe(select(selectBusinessOwner)).subscribe((result:any) => {
        this.businessStakeholder=result;
       });

    }


  ngOnInit() {
   if(this.previousResponse){
     this.bDocument1=this.previousResponse[0].document;
     this.bDocument2=this.previousResponse[1].document;
   }
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.sendImageApi(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }

  // sendImageApi(file){
  //   this.ngxService.start();
  //   this.businessDetailService.sendDocument(file).subscribe((result) => {
  //     if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
  //       this.document=result.responseData.fileID;
  //       let businessRegDoc = new Document(null,"Document","Business Registration",this.document, 1);
  //       this.businessDocument.push(businessRegDoc);
  //       this.ngxService.stop();
  //       this.isSuccess = true;
  //       this.isError = false;
  //     }
  //     else {
  //       this.isError = true;
  //       this.ngxService.stop();
  //     }
  //   }, error => { console.log(error) })
  // }

  sendImageApi(file){
    this.ngxService.start();
    this.businessDetailService.sendDocument(file, "ID", "Business Registration").subscribe((result) => {
      if (result != null && result.code === "DAO_SUCCESS_00" && result.responseData != null) {
        this.document = result.responseData.Date.document;
        let businessRegDoc = new Document(null,"Document","Business Registration",this.document, 1);
        this.businessDocument.push(businessRegDoc);
        this.ngxService.stop();
        this.isSuccess = true;
        this.isError = false;
      }
      else {
        this.isError = true;
        this.ngxService.stop();
      }
    }, error => { console.log(error) })
  }

  addBusinessDocument(modal){
    this.submitted = true;
    if (this.documentsForm.invalid){
      return;
    }
  else{
    this.store.dispatch(addBusDocument(this.businessDocument));

    this.accDetail=new Account(this.prevCustomer.id,this.prevCustomer.fullName,this.prevCustomer.mobileNo,
      this.prevCustomer.email,this.prevCustomer.type,this.prevCustomer.status); 

      let bussinessAddressModel= new Address(null,this.prevBusinessAddr.line1,this.prevBusinessAddr.line2,
      this.prevBusinessAddr.city,this.prevBusinessAddr.postalCode,this.prevBusinessAddr.state,this.prevBusinessAddr.country);

      let finalBusiness= new Business(null,this.prevBusiness.fullName,this.prevBusiness.registrationNo,this.prevBusiness.website,
      this.prevBusiness.status,bussinessAddressModel,this.prevBusiness.registrationType,this.prevBusiness.industry,this.businessDocument,this.accDetail,this.businessStakeholder,
      this.prevCustomer.email);
        this.business=finalBusiness;

        this.createUser(modal);
    // this.router.navigate(['/personalDetails']);
  }
  }


  createUser(modal){
    this.ngxService.start();
    this.signUpService.createBusiness(this.business).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        let obj=result.responseData.user;
        localStorage.setItem('businessID', obj.id);
        this.sendCustomerNotify(obj.id)
        const merchantModel = new Business(obj.id,obj.fullName,obj.registrationNo,
          obj.website,obj.status,obj.businessAddresses,obj.registrationType,obj.industry,obj.businessDocuments,
          this.accDetail,this.businessStakeholder, this.prevCustomer.email)
          this.store.dispatch(addBusiness(merchantModel))
        this.isSuccess = true;
        this.ngxService.stop();
        this.router.navigate(['/personalDetails']);
        // this.openmodal(modal);
      }
      else {
        this.ngxService.stop();
        this.isError = true;
      }
    }, error => { console.log(error) });

  }

  openmodal(content){
    this.modalService.open(content,   {size: 'lg', windowClass: 'modal-xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate(['/personalDetails']);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onPrevious(){
    this.router.navigate(['/businessAddress']);
  }

  sendCustomerNotify(id: any) {
    this.signUpService.sendCustomerNotify(id).subscribe((resp: any) =>{
      console.log("customer notify res", resp);
      
    });
  }

}
