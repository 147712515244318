import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../../sign-up/system/system.constants'; 
import { Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../http.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenricateService {

  private baseUrl = environment.api_base_url;
  private digibase_url = environment.digibase_url
  private headers = SystemConstants.header;

  constructor(private httpService:HttpService,
    private ngxLoader: NgxUiLoaderService,) { }

  verifyEmail(request: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    // let request = JSON.stringify({ "user": Account});   
    return this.httpService.get(this.baseUrl+`onboarding/account/search?email=${request}`,{headers})
    .pipe(map((res:any) => {
      // this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      // this.ngxLoader.stop();
      return error;
    })); 
  }

  updateOnboardingFlag(body: any): Observable<any> {
    let token = this.getToken();  
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    headers.append("Authorization", `QBR ${token}`);
    return this.httpService.post(this.digibase_url+"admin/user/updateOnboardStatus" ,body,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  getToken(){
    return localStorage.getItem(SystemConstants.b64EncodeUnicode('auth_token'));    
  }

  setToken(token: any){
    localStorage.setItem(SystemConstants.b64EncodeUnicode('auth_token'), token);    
  }

  // method to encode string into base64
  public static b64EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  public static b64DecodeUnicode(str: string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

}
