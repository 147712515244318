<app-card-large>

      <div class="dyEAXN">
        <div class="bCDrPV djdIYm">
          <div class="mjYRwVK">
            <div class="main-panel__content">
              <div class="main-panel__content">
                <h1  class="main-panel__heading nonreferral">
                Business Information
              </h1>
              <h2 class="main-panel__subheading">
                Please provide details about your business
              </h2>
            </div>
            </div>
            <form name="form" [formGroup]="informationForm">
              <div class="row">
                <div class="col-sm-11">
                <label
                    data-component="form-input-name-label"
                    class="form__label"
                    for="name"
                    >Registration Number <span style="color: red">*</span></label
                  >
                  <input
                    id="name"
                    name="name"
                    type="text" (change)="verifyCompanyName()"
                    formControlName="registrationNo"
                    [ngClass]="{ invalid: submitted && f.registrationNo.errors }"
                    autocomplete="name"
                    class="form__input"
                    value=""
                  />
                  <div
                    *ngIf=" f.registrationNo.errors"
                    class="text-danger"
                  >
                    <div *ngIf=" submitted && f.registrationNo.errors.required">
                      Registration Number is required
                    </div>
                    <div *ngIf="f.registrationNo.errors.minlength">
                      Required atleast 8 numbers
                    </div>
                    <div *ngIf=" f.registrationNo.errors.companyNotExist">
                      Company not active. Please check the registration number.
                    </div>
                    <div *ngIf="f.registrationNo.errors.invalidRegistrationNumber">
                     Invalid registration number.
                    </div>
                  </div>
                </div>
              </div>
      
              <div class="row">
                <div class="col-sm-5">

                  <label
                  data-component="form-input-email-label"
                  class="form__label"
                  for="email"
                  >Business Name <span style="color: red">*</span></label
                >
                <input
                  id="text"
                  name="text"
                  type="text"
                  formControlName="fullName"
                  [ngClass]="{ invalid: submitted && f.fullName.errors }"
                  autocomplete="username"
                  class="form__input validate-empty"
                  value=""/>
                <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                  <div *ngIf="f.fullName.errors.minlength">
                    Required atleast 5 Characters
                  </div>
                  <div *ngIf="f.fullName.errors.required">
                    Business Name is required
                  </div>
                </div>
               
                </div>
      
                <div class="col-sm-4">
                  <label
                    for="team_size"
                    class="form__label"
                    >Registration Type <span style="color: red">*</span></label
                  >
                  <select
                    name="team_size"
                    id="team_size"
                    formControlName="registrationType"
                    [ngClass]="{ invalid: submitted && f.registrationType.errors }"
                    class="form__select">
                    <option *ngFor="let type of registrationType" [value]="type.type">
                      {{ type.type }}
                    </option>
                    </select>
                  <div
                    *ngIf="submitted && f.registrationType.errors"
                    class="text-danger"
                  >
                    <div *ngIf="f.registrationType.errors.required">
                      Registration type is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">
                    <label
                      for="team_size"
                      class="form__label"
                      >Website<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      autocomplete="name"
                      formControlName="website"
                      [ngClass]="{ invalid: submitted && f.website.errors }"
                      class="form__input validate-empty"
                      value=""
                    />
                    <div *ngIf="submitted && f.website.errors" class="text-danger">
                      <div *ngIf="f.website.errors.required">Website is required</div>
                      <div *ngIf="f.website.errors?.pattern">
                        Please provide a valid Website
                      </div>
                    </div>
                </div>
                <div class="col-sm-4">
                      <label
                        for="team_size"
                        class="form__label"
                        >Industry<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        formControlName="industry"
                        [ngClass]="{ invalid: submitted && f.industry.errors }"
                        class="form__select"
                      >
                        <!-- <option value="" disabled selected>
                          What is Your Business Industry?
                        </option> -->
                        <option
                          *ngFor="let industry of industries"
                          [value]="industry.name"
                        >
                          {{ industry.name }}
                        </option>
                        </select>
                      <div *ngIf="submitted && f.industry.errors" class="text-danger">
                        <div *ngIf="f.industry.errors.required">Industry is required</div>
                      </div>
                </div>
              </div>
                <div class="Stakeholders-add">
                  <h5 class="main-panel__subheadings">Stakeholders</h5>
                   <!-- Show error message if there is an error -->
                  <div style="color: red;" *ngIf="showErrorMessage" class="error-message">
                   {{errorMessage}}
                  </div>
                    <form class="col s12" [formGroup]="accountItemsForm">
                    <div formArrayName="accountVos">
                      <div class="border-card"
                        
                        *ngFor="
                          let detail of accountItemsForm.get('accountVos')?.controls;
                          let i = index
                        "
                      >
                        <ng-container class="card" [formGroupName]="i">
                          <!-- {{detail.get('email').errors | json}} -->
                          <div class="row">
                            <div class="col-sm-3">
                              <label class="form__label">First Name</label>
                              <input
                                class="form__input validate-empty"
                                formControlName="firstName"
                                type="text"
                                min="1"
                                required
                                [ngClass]="{
                                  invalid: submitted && detail.get('firstName').errors
                                }"
                              />
                              <div *ngIf="submitted && detail.get('firstName').errors">
                                <div
                                  *ngIf="
                                    submitted &&
                                    detail.get('firstName').errors?.required
                                  "
                                  class="text-danger"
                                >
                                  Required
                                </div>
                                <div
                                  *ngIf="
                                    submitted &&
                                    detail.get('firstName').errors?.minlength
                                  "
                                  class="text-danger"
                                >
                                  Atleast 3 Ch.
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <label class="form__label" >Last Name</label>
                              <input
                                class="form__input validate-empty"
                                formControlName="lastName"
                                type="text"
                                min="1"
                                required
                                [ngClass]="{
                                  invalid: submitted && detail.get('lastName').errors
                                }"
                              />
                              <div *ngIf="submitted && detail.get('lastName').errors">
                                <div
                                  *ngIf="
                                    submitted && detail.get('lastName').errors?.required
                                  "
                                  class="text-danger"
                                >
                                  Required
                                </div>
                                <div
                                  *ngIf="
                                    submitted &&
                                    detail.get('lastName').errors?.minlength
                                  "
                                  class="text-danger"
                                >
                                  Atleast 3 Ch.
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <label class="form__label">Role</label>
                              <input
                                class="form__input validate-empty"
                                formControlName="designation"
                                type="text"
                                required
                                [ngClass]="{
                                  invalid: submitted && detail.get('designation').errors
                                }"
                              />
                              <div
                                *ngIf="submitted && detail.get('designation').errors"
                              >
                                <div
                                  *ngIf="submitted && detail.get('designation').errors"
                                  class="text-danger"
                                >
                                  Required
                                </div>
                              </div>
                            </div>
                          </div>
                         
                      <div class="row">
                       
                        <div class="col-sm-3">
                              <label class="form__label" >Email</label>
                              <input
                                class="form__input validate-empty"
                                formControlName="email"
                                type="text"
                                required
                                [ngClass]="{
                                  invalid: submitted && detail.get('email').errors
                                }"
                              />
                              <div *ngIf="submitted && detail.get('email').errors">
                                <div
                                  *ngIf="
                                    submitted && detail.get('email').errors?.required
                                  "
                                  class="text-danger"
                                >
                                  Required
                                </div>
                                <div
                                  *ngIf="
                                    submitted && detail.get('email').errors?.pattern
                                  "
                                  class="text-danger"
                                >
                                  Invalid Email
                                </div>
                              </div>
                        </div>
                        <div class="col-sm-3">
                              <label class="form__label" >Phone</label>
                              <input
                                class="form__input validate-empty"
                                formControlName="mobileNo"
                                type="text"
                                [mask]="'999999999999999'"
                                required
                                [ngClass]="{
                                  invalid: submitted && detail.get('mobileNo').errors
                                }"
                              />
                              <div *ngIf="submitted && detail.get('mobileNo').errors">
                                <div
                                  *ngIf="submitted && detail.get('mobileNo').errors"
                                  class="text-danger"
                                >
                                  Required
                                </div>
                              </div>
                        </div>
                        <div class="col-sm-3">
                          <label class="form__label">Gender</label>
                          <select
                            class="form__select"
                            formControlName="gender"
                            [ngClass]="{
                              invalid: submitted && detail.get('gender').errors
                            }"
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          <div
                            *ngIf="submitted && detail.get('gender').errors"
                            class="text-danger"
                          >
                            Required
                          </div>
                    </div>
                      </div>
                          
                     
                       <div class="input-field ">
                            <a
                              (click)="removeItemDetail(i)"
                              class="mb-6 btn-floating waves-effect waves-light red accent-2"
                            >
                            <img src="../../../assets/close.png" alt="">
                            
                            </a>
                          </div>
                        </ng-container>
                      </div>
                    </div>
      
                    <div class="row">
                      <div class="col m12 s12">
                        <a  (click)="addItemDetail()" class=" accent-2">
                        <img src="../../../assets/Vector.png" alt="">
                       </a>
                      </div>
                    </div>
                  </form>
                
              </div>
      </form>
      <div class="eBKYjJ cgMkag">
        <button
          data-component="primary-button"
          type="submit"
          role="button"  [disabled]="isContinueButtonDisabled"
          tabindex="0"
          class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
          (click)="addBusinessInfo()"
        >
          Continue
        </button>
      </div>
        </div>
        </div>
      
               
        <!-- <div class="eBKYjJ cgMkag">
          <button
            data-component="primary-button"
            type="submit"
            role="button" [disabled]="isContinueButtonDisabled"
            tabindex="0"
            class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
            (click)="addBusinessInfo()"
          >
            Continue
          </button>
        </div> -->
        </div>
    
</app-card-large>
