
<card-container>
    <div class="dyEAXN">
        <!-- RIGHT PANEL-->
        <div class="bCDrPV djdIYm">
            <div class="mjYRwVK">
                <!-- FORM CONTENT-->
                <div class="hnZgAZ">
                    <div class="husZCp">
                        <h1 data-component="heading"
                            class="styles__Root-sc-1myek4t-0 fdExrC Heading__Head-junqdu-1 lgVgrq">Thank You!</h1>
                        <h2 data-component="subheading"
                           
                        class="styles__Root-sc-1myek4t-0 cclCHJ Heading__SubHead-junqdu-2 goFGMk">You have successfully completed the business registration process and we have received your KYB documents. Our team will review them and will get back to you</h2>
                        <div class="eBKYjJ cgMkag">
                            <button data-component="primary-button" type="submit" 
                                role="button" tabindex="0" (click) = "updateOnboardingFlag()"
                                class="epjTBn"
                               >Continue</button>
                        </div>
                    </div>
                    
                </div>
    
              
            </div>
            <!--FORM CONTENT -->
        </div>
    </div> <!-- RIGHT PANEL-->
</card-container>
