import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Store} from '@ngrx/store';
import {addCustomer} from '../store/action/customer.actions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AccountService } from '../service/account.service';
import { Account } from '../model/account'


declare var $: any;

@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})


export class AccountDetailsComponent implements OnInit {
  public submitted = false;
  public firstName: any = localStorage.getItem('firstName');
  public lastName: any = localStorage.getItem('lastName');

  accountForm = this.formBuilder.group({
    userEmail :   ['',Validators.required],
    fullName :    ['',[Validators.required]],
    phoneNumber : ['',Validators.required]
  })
  

  get f(){return this.accountForm.controls;}
  
  constructor(private accountService: AccountService,private store: Store<Account>,private ngxService: NgxUiLoaderService,
    private router: Router,private formBuilder : FormBuilder) {

      this.accountForm.get('phoneNumber').disable();
    }

  ngOnInit() {
    let fullName = this.firstName+' '+this.lastName
    this.accountForm.get("userEmail").setValue(localStorage.getItem('userEmail')) ;
    this.accountForm.get('phoneNumber').setValue(localStorage.getItem('userPhone'));
    this.accountForm.get('fullName').setValue(fullName);
    localStorage.setItem('userFullName', fullName);

    // if((this.data.storage != undefined || this.data.storage != null)){
    //     if(this.data.storage.check==1){
    //       this.accountForm.patchValue({
    //         fullName:localStorage.getItem('userFullName'),
    //         phoneNumber:localStorage.getItem('userPhone')
    //       })
    //     }
    // }
  }


  addAccount(){
    this.submitted = true;
    if (this.accountForm.invalid){
      return;
    }
    else{
      
      let accountModel = new Account(null,this.accountForm.getRawValue().fullName,this.accountForm.getRawValue().phoneNumber, this.accountForm.getRawValue().userEmail,"operator",0);
      this.ngxService.start();
      this.accountService.saveUser(accountModel).subscribe((result) => {
        if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
          this.ngxService.stop();
          // localStorage.setItem('userPhone',this.accountForm.value.phoneNumber+"");
          localStorage.setItem('userId', result.responseData.user.id+"");
          this.router.navigate(['/businessDetails']);
          // localStorage.setItem('userFullName', result.responseData.user.fullName);
        //   this.userId = result.responseData.user.id;
        //   this.isSuccess = true;
        //   this.isError = false;
        let res = new Account(result.responseData.user.id,result.responseData.user.fullName,this.accountForm.value.phoneNumber, this.accountForm.value.userEmail,"operator",0);
        this.store.dispatch(addCustomer(res))
        // }
        // else {
        //   this.isError = true;
        }
        else{
          this.ngxService.stop();
        }
        // this.router.navigate(['/verifyOTP']);
      }, error => { console.log(error) });
    }



  }

  onPrevious(){
    this.router.navigate(['/emailCheck']);
  }

}