// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_base_url: 'http://localhost:8080/dao-spring-api/',
  // api_base_url:'http://localhost:8080/dao-spring-api/',
  // digibase_url:'http://localhost/digibase-api/',
  // idScanUrl: 'http://idscan-qbanc.evantagesoft.com',

  // staging

  api_base_url: 'https://app.qbanc.uk/dao-spring-api/',
  digibase_url: 'https://app.qbanc.uk/digibase-api/',
  idScanUrl: 'https://idscan.qbanc.uk/',
  portal_redirect_url: 'https://app.qbanc.uk/verify-dashboard',
  api_version_one: 'v1/'

//  dev

  // api_base_url:'https://apiqbanc.evantagesoft.com/dao-spring-api/',
  // digibase_url:'https://apiqbanc.evantagesoft.com/digibase-api/',
  // idScanUrl: 'http://idscan-qbanc.evantagesoft.com',
  // portal_redirect_url: 'https://portal-qbanc.evantagesoft.com//verify-dashboard',
  // api_version_one: 'v1/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
