import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {select, Store} from '@ngrx/store';
import {selectPersonal} from '../store/selector/personal.selectors'
import {selectBusiness} from '../../../app/business-details/store/selector/business.selectors'
import {addPerson} from '../store/action/personal.actions';
import {Business} from '../../business-details/model/business';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {Account} from '../../business-details/model/account';
import {BusinessCustomer} from '../model/businessCustomer'

declare var $: any;

@Component({
  selector: 'personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})


export class PersonalDetailsComponent implements OnInit {
  public business : Business;
  public submitted = false;
  public userEmail : string
  public accType:string;
  public userFullName : string
  public userPhoneNum : string
  public previousResponse:any;
  public previousBusinessResponse:any;
  public firstName: any = localStorage.getItem('firstName');
  public lastName: any = localStorage.getItem('lastName');


  personalDetailForm = this.formBuilder.group({
    firstName    : ['', [Validators.required, Validators.minLength(3)]],
    lastName     : ['', [Validators.required, Validators.minLength(3)]],
    dateOfBirth  : ['', [Validators.required, this.ageValidator]],
    gender       : ['', Validators.required],
    nationality  : ['', Validators.required] 
  })

  get f(){return this.personalDetailForm.controls}; 

  constructor(private router: Router,private store: Store<BusinessCustomer>,
  private formBuilder : FormBuilder){

    this.store.pipe(select(selectBusiness)).subscribe((result) => {
      this.previousBusinessResponse=result;
    });

    this.store.pipe(select(selectPersonal)).subscribe((result:any) => {
      this.previousResponse=result;
    });
  }

  ngOnInit() {
    this.personalDetailForm.controls.firstName.setValue(this.firstName);
    this.personalDetailForm.get('firstName')?.disable();
    this.personalDetailForm.controls.lastName.setValue(this.lastName);
    this.personalDetailForm.get('lastName')?.disable();

    //  this.business=this.data.storage.businessFullInfo;
    this.userEmail=localStorage.getItem('userEmail');
    this.userFullName=localStorage.getItem('userFullName');
    this.userPhoneNum=localStorage.getItem('userPhone');
    if(this.previousResponse){
      let checkPrev=this.previousResponse.accountVo;
        if(checkPrev){
          this.accType=this.previousResponse.accountVo.type;
        }
        else{
          this.accType="operator";
        }
        this.personalDetailForm.patchValue(this.previousResponse)
      }
  }

  addPersonalDetail(){
    this.submitted = true;
    if (this.personalDetailForm.invalid){
      return;
    }
else{
  var customerList: BusinessCustomer[]=[];  
  let accountModel = new Account(null, this.userFullName, this.userPhoneNum, this.userEmail, "ind");

  var custModel = new BusinessCustomer(this.previousResponse.id,this.previousBusinessResponse.id, accountModel,this.accType,this.personalDetailForm.getRawValue().firstName, 
  this.personalDetailForm.getRawValue().lastName,this.personalDetailForm.value.gender,this.personalDetailForm.value.nationality, 
  this.personalDetailForm.value.dateOfBirth,3,null,null);

  customerList.push(custModel);

  this.store.dispatch(addPerson(custModel))
  this.router.navigate(['/personalAddress']);
}
  }

  onPrevious(){
    this.router.navigate(['/businessDocuments']);
  }

  ageValidator(control: FormControl) {
    if(control.value == null || control.value == '')
      return null;
    let today = new Date();
    let birthDate = new Date(control.value);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }    
    if(age >= 18){
      return null;
    }
    return {
      minAge: true
    };
  }
}