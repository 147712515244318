import { Injectable } from '@angular/core';
import { HttpService } from '../../http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { Headers } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../system/system.constants'; 
import { AuthenricateService } from 'src/app/email-check/service/authenricate.service';


@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  private baseUrl = environment.api_base_url;
  private digibase_url= environment.digibase_url
  private headers = SystemConstants.header;
  
  constructor(private httpService:HttpService, private authorizationService: AuthenricateService,
    private ngxLoader: NgxUiLoaderService) { }

  saveUser(Account: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    let request = JSON.stringify({ "user": Account});   
    return this.httpService.post(this.baseUrl+"onboarding/account/",request,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  createCustomer(Account: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    let request = JSON.stringify({ "customer": Account});    
    return this.httpService.post(this.baseUrl+"customer/signup",request,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }


  createBusiness(object: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    let request = JSON.stringify({ "business": object});    
    return this.httpService.post(this.baseUrl+"business/register",request,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  sendOtp(Otp: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    let request = Otp
    return this.httpService.post(this.baseUrl+"auth/verify/otp",request,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }

  sendCustomerNotify(id: any): Observable<any> {
    let token = this.authorizationService.getToken();
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    headers.append("Authorization", `QBR ${token}`);
    // let request = JSON.stringify({ "user": Account});   
    return this.httpService.get(this.digibase_url+`business/sendCustomerNotify?id=${id}`,{headers})
    .pipe(map((res:any) => {
      // this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      // this.ngxLoader.stop();
      return error;
    })); 
  }

}
